import * as yup from 'yup'

export const validateCreateUsers = yup.object().shape({
    first_name: yup
    .string()
    .required('Campo requerido.')
    .min(4, 'El NOMBRE debe tener más de 3 caracteres'),
    last_name: yup
    .string()
    .required('Campo requerido.'),
    dni: yup
    .string()
    .required('Campo requerido.')
    .min(4, 'La cédula debe tener más de 3 caracteres numéricos'),
    phone: yup
    .string()
    .required('Campo requerido.')
    .min(4, 'El TELÉFONO debe tener más de 3 caracteres numéricos'),
    email: yup
    .string()
    .required('Campo requerido.')
    .email('Introduzca un correo electrónico válido.'),
    password: yup
    .string()
    .required('Campo requerido.')
    .min(6, 'Debe introducir una contraseña de al menos 6 caracteres.'),
    role: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['user', 'admin', 'seller', 'integration'], 'Debes elegir entre estas opciones'),
    status: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['active', 'inactive', 'pending security verification', 'pending user data', 'pending validation'], 'Debes elegir entre estas opciones'),
})

