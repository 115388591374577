import { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import {
  coursesChangeModalState,
  refreshTableCourses,
  selectCoursesForUpdate,
  selectCoursesRefreshTable,
  selectIsUpdateCourses,
  setIsCoursesForUpdate,
} from "../../../redux";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { DataCourses, DataCoursesForUpdate } from "../../../redux/@types";
import { validateCreateCourses, validateUpdateCourses } from "../../../helpers";
import {
  updateCourses,
  createCourses,
} from "../../../redux/actions/courses.actions";

export const useCourses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUpdateCourses = useSelector(selectIsUpdateCourses);
  const dataUpdateCourses = useSelector(selectCoursesForUpdate);
  const isRefreshTableCourses = useSelector(selectCoursesRefreshTable);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalCoursesState = () => {
    dispatch(setIsCoursesForUpdate(false));
    navigate(`/courses/new`);
    // dispatch(coursesChangeModalState());
  };

  const formMethodsCreate = useForm<DataCourses>({
    resolver: yupResolver(validateCreateCourses),
  });

  const formMethodsUpdate = useForm<DataCoursesForUpdate>({
    resolver: yupResolver(validateUpdateCourses),
  });

  useEffect(() => {
    if (isUpdateCourses) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCourses });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCourses, dataUpdateCourses]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateCourses({
      id,
      status: value,
    })
    .then((res: Partial<DataCourses>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado del curso con id:${res.id}`
        );
        dispatch(refreshTableCourses(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (
    data: DataCourses | DataCoursesForUpdate
  ) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCourses) {
      //console.log("Actualiando===>", data);
      await updateCourses({
        id: data.id,
        name: data.name,
        status: data.status,
        product_id: Number(data.product_id),
        category_id: Number(data.category_id),
        rating_score: data.rating_score,
        small_description: data.small_description,
        ...(data.description && {description: data.description.replace(/&nbsp;/g, ' ')}),
        level: data.level,
        duration: data.duration,
        authors: data.authors,
        position: data.position,
        path_video: data.path_video,
        path_image: data.path_image,
      })
        .then((res: Partial<DataCourses>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            dispatch(coursesChangeModalState());
            navigate(`/courses`);
            message.success(
              `Se ha actualizado con éxito el curso de curso con id:${res.id}`
            );
            dispatch(refreshTableCourses(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createCourses(data)
        .then((res: DataCourses) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(coursesChangeModalState());
            navigate(`/courses`);
            message.success(
              `Se ha creado con éxito el curso de curso con id:${res.id}`
            );
            dispatch(refreshTableCourses(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCourses) {
      tableProps?.refresh();
      dispatch(refreshTableCourses(false));
    }
  }, [isRefreshTableCourses]);
  return {
    //state
    isUpdateCourses,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalCoursesState,
    updateStatus,
  };
};
