import { IAttributeState, ISingleAttribute } from "../@types/attributes";
import { attributesAction } from "../action-types";

type attributesType =
| { type: typeof attributesAction.ATTRIBUTE_MODAL_STATE }
| { type: typeof attributesAction.GET_ATTRIBUTES_FOR_UPDATE; payload: ISingleAttribute }
| { type: typeof attributesAction.IS_ATTRIBUTES_FOR_UPDATE; payload: boolean }
| { type: typeof attributesAction.SWITCH_ATTRIBUTES_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IAttributeState ={
    attributesForUpdate: undefined,
    isModalAttributesOpen: false,
    isUpdateAttributes: false,
    refreshTableAttributes: false,
}

export default function attributesReducer(state: IAttributeState = INITIAL_STATE, action: attributesType): IAttributeState {
    switch(action.type){
        case attributesAction.ATTRIBUTE_MODAL_STATE:
            return {
                ...state,
                isModalAttributesOpen: !state.isModalAttributesOpen
            }
        case attributesAction.GET_ATTRIBUTES_FOR_UPDATE:
            return{
                ...state,
                attributesForUpdate: action.payload
            }
        case attributesAction.IS_ATTRIBUTES_FOR_UPDATE:
            return{
                ...state,
                isUpdateAttributes: action.payload
            }
        case attributesAction.SWITCH_ATTRIBUTES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableAttributes: action.payload
            }
        default:
            return state
    }
}