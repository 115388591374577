import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { useFulfillmentCompany } from "../../../../modules";

import {
  selectFulfillmentCompanyForUpdate,
  selectIsUpdateFulfillmentCompany,
  selectModalFulfillmentCompany,
} from "../../../../redux";
import { DataFulfillmentCompany } from "../../../../redux/@types";
import {  InputSelect, InputText } from "../inputs";
import { WrapperModalFulfillmentCompany } from "../../../../modules/fulfillmentCompany/ui/styled";

const { Title } = Typography;

export const ModalFulfillmentCompany = () => {
  const modalFulfillmentCompany = useSelector(selectModalFulfillmentCompany);
  const isUpdateFulfillmentCompany = useSelector(
    selectIsUpdateFulfillmentCompany
  );
  const updateFulfillmentCompany = useSelector(
    selectFulfillmentCompanyForUpdate
  );

  const {
    isLoading,
    changeModalFulfillmentCompanyState,
    onSubmitCreateOrUpdate,
  } = useFulfillmentCompany();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataFulfillmentCompany>();

  return (
    <WrapperModalFulfillmentCompany
      title={
        isUpdateFulfillmentCompany
          ? `Actualizando transportadora: '${updateFulfillmentCompany?.id!}'`
          : `Creando transportadora`
      }
      open={modalFulfillmentCompany}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalFulfillmentCompanyState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText
          placeHolder="Yoyoso Envíos"
          name="name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Descripción:
        </Title>
        <InputText
          placeHolder="Es una transportadora para..."
          name="description"
          control={control}
          rows
        />
      </Col>
      {/* <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Path:
        </Title>
        <InputText
          placeHolder="/public/img.png"
          name="path"
          control={control}
        />
      </Col> */}
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Tipo:
        </Title>
        <InputSelect
          name="type"
          control={control}
          dataOptions={[
            { title: "Peso", value: "weight" },
            { title: "Unidad", value: "units" },
            { title: "Precio", value: "price" },
            { title: "Volumen", value: "volume" },
          ]}
          label="Tipo"
        />
      </Col>
      {isUpdateFulfillmentCompany && (
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Estado:
          </Title>
          <InputSelect
            name="status"
            control={control}
            dataOptions={[
              { title: "Activo", value: "active" },
              { title: "Inactivo", value: "inactive" },
            ]}
            label="Estado"
          />
        </Col>
      )}
      {/* <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Integración:
        </Title>
        <InputText
          placeHolder="true o false"
          name="integration"
          control={control}
        />
      </Col> */}
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateFulfillmentCompany
          ? "Actualizar información"
          : "Crear transportadora"}
      </Button>
    </WrapperModalFulfillmentCompany>
  );
};
