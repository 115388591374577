import { Button, Col, Row, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import {
  AdminMenuLayout,
  InputSelect,
  InputText,
} from "../../../../shared/components";
import { useCMS } from "../../hook";
import { WrapperCardCMS } from "../styled";
import { useParams } from "react-router-dom";
import { TableDetails } from "./tableDetails";

const { Title } = Typography;
export const DiscountById = () => {
  const { id } = useParams();
  const { isLoading, onSubmitCreateOrUpdate } = useCMS();

  const { control, handleSubmit: onSubmit } = useFormContext<any>();

  return (
    <AdminMenuLayout pageDescription="Descuentos" title="Descuentos">
      <WrapperCardCMS>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              // padding: 5,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                marginBottom: "1.5px",
                color: "var(--primary)",
                marginRight: 7,
              }}
              level={2}
            >
              {id !== 'new' ? 'Actualizando Descuentos' : 'Creando template de descuento por unidad'}
            </Title>
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
            </Title>
            <InputText
              name="name"
              control={control}
              placeHolder="Ingrese el nombre"
            />
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Estado:
            </Title>
            <InputSelect
              name="status"
              control={control}
              dataOptions={[
                { title: "Activo", value: "active" },
                { title: "Inactivo", value: "inactive" },
              ]}
              label="Estado"
            />
          </Col>
          <Button
            style={{
              width: "100%",
              marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
          >
            {id !== 'new' ? 'Actualizar información' : 'Crear Template'}
          </Button>
        </Row>
        {id !== 'new' && (
          <>
            <Col
                span={24}
                style={{
                  width: "100%",
                  display: "flex",
                  // padding: 5,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <Title
                  style={{
                    marginBottom: "1.5px",
                    color: "var(--primary)",
                    marginRight: 7,
                  }}
                  level={2}
                >
                  Rangos de descuento
                </Title>
              </Col>
            <TableDetails />
        </>
        )}
      </WrapperCardCMS>
    </AdminMenuLayout>
  );
};
