import { ISingleAttributeValues } from "../@types";

export const ATTRIBUTE_VALUES_MODAL_STATE =
  "[ ATTRIBUTE_VALUES ] Attribute Values Modal State";

export interface IAttributeValuesModalState {
  type: typeof ATTRIBUTE_VALUES_MODAL_STATE;
}

export const GET_ATTRIBUTE_VALUES_FOR_UPDATE =
  "[ ATTRIBUTE_VALUES ] Get List Attribute Values Groups For Update";

export interface IGetAttributeValuesForUpdate {
  type: typeof GET_ATTRIBUTE_VALUES_FOR_UPDATE;
  payload: ISingleAttributeValues;
}

export const IS_ATTRIBUTE_VALUES_FOR_UPDATE =
  "[ ATTRIBUTE_VALUES ] Is List Attribute Values Groups For Update";

export interface IsAttributeValuesForUpdate {
  type: typeof IS_ATTRIBUTE_VALUES_FOR_UPDATE;
  payload: boolean;
}

export const SWITCH_ATTRIBUTE_VALUES_REFRESH_TABLE =
  "[ ATTRIBUTE_VALUES ] Switch List Attribute Values Groups Refresh Table";

export interface ISwitchAttributeValuesRefreshTable {
  type: typeof SWITCH_ATTRIBUTE_VALUES_REFRESH_TABLE;
  payload: boolean;
}
