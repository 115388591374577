import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { WrapperModalCities } from "../../../../modules";
import {
  selectAttributesForUpdate,
  selectIsUpdateAttributes,
  selectModalAttributes,
} from "../../../../redux";
import { DataCities } from "../../../../redux/@types";
import { InputText } from "../inputs";
import { useAttributes } from "../../../../modules/attributesTest";

const { Title } = Typography;

export const ModalAttributes = () => {
  const modalCities = useSelector(selectModalAttributes);
  const isUpdateCity = useSelector(selectIsUpdateAttributes);
  const updateCity = useSelector(selectAttributesForUpdate);

  const { isLoading, changeModalAttributeState, onSubmitCreateOrUpdate } =
    useAttributes();

  const { control, handleSubmit: onSubmit } = useFormContext<DataCities>();
  return (
    <WrapperModalCities
      title={
        isUpdateCity
          ? `Actualizando ciudad: '${updateCity?.id!}'`
          : `Creando ciudad`
      }
      open={modalCities}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalAttributeState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre del atributo:
        </Title>
        <InputText
          placeHolder="Nombre atributo"
          name="name"
          control={control}
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateCity ? "Actualizar atributo" : "Crear atributo"}
      </Button>
    </WrapperModalCities>
  );
};
