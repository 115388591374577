import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components";
import { WrapperCardCMS } from "./styled";
import { TableCMS } from "./TableCMS";
import { useCMS } from "../hook";

export const CMS = () => {
  const {  changeModalCMSState } = useCMS();

  return (
    <AdminMenuLayout pageDescription="CMS" title="CMS">
      <WrapperCardCMS>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCMSState}
            >
              Agregar sistema de gestión de contenido
            </Button>
          </Col>
        </Row>
        <TableCMS />
      </WrapperCardCMS>
    </AdminMenuLayout>
  );
};
