import { Avatar, Button, Col, Modal, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { productsService } from "../../../shared/services";
import { useProducts, useTableProduct } from "../hooks";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import { IProducts } from "../../../redux/@types";
import { decimal } from "../../../shared/utils/Helper";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { ProductsForm } from "./productById";

const { Title } = Typography;

interface Props {
  fetchQuery?: any;
}

export const TableProductsChildren = ({ fetchQuery }: Props) => {
  const { showDeleteConfirm, changeProductsForUpdate } = useTableProduct();
  const { setTableProps, updateStatus, isLoading } = useProducts();
  const params = useParams();
  const columns: TableColumns<IProducts> = [
    {
      title: "ID",
      width: 7,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Foto",
      width: 7,
      dataIndex: "media",
      key: "media",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {item.media[0] && (
              <Avatar
                size={64}
                src={`${PATH_S3_BUCKET}/${item.media.sort(
                  (a, b) => b.priority - a.priority
                )[0]?.path!}`}
              />
            )}
          </Col>
        );
      },
    },
    {
      title: "Nombre",
      width: 12,
      dataIndex: "name",
      key: "name",
      filterType: "data_value",
    },
    {
      title: "Color",
      width: 12,
      dataIndex: "color",
      key: "color",
      filterType: "text",
    },
    {
      title: "Talla",
      width: 6,
      dataIndex: "size",
      key: "size",
      filterType: "text",
    },
    {
      title: "Genero",
      width: 10,
      dataIndex: ["category", "name"],
      key: "category",
    },
    {
      title: "Código",
      width: 10,
      dataIndex: "code",
      key: "code",
      filterType: "data_value",
    },
    {
      title: "Precio",
      width: 10,
      dataIndex: "price",
      key: "price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.price)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "IVA",
      width: 10,
      dataIndex: "tax_rule_id",
      key: "tax_rule_id",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item.tax.name}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Cantidad",
      width: 6,
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Estado",
      width: 12,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
              disabled={isLoading}
              onClick={() => {
                if (item.status === "active") {
                  updateStatus("inactive", item.id);
                } else if (item.status === "inactive") {
                  updateStatus("active", item.id);
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 10,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeProductsForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  const { navigateProductsCreate } = useProducts();

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={() => navigateProductsCreate(params)}
      >
        Agregar nuevo Sub-producto
      </Button>
      <Table<IProducts>
        columns={columns}
        fetchQueryProps={{
          $sort: { id: -1 },
          parent_id: params.id,
          ...fetchQuery,
        }}
        service={productsService}
        onLoad={setTableProps}
      />
    </>
  );
};
