import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { DataAttributeValues } from "../../../redux/@types";
import { useAttributeValues } from "./useAttributeValues";
import {
  attributeValuesChangeModalState,
  deleteAttributeValues,
  refreshTableAttributeValues,
  setAttributeValuesForUpdate,
  setIsAttributeValuesForUpdate,
} from "../../../redux/actions/attributes.actions";

const { confirm } = Modal;

export const useTableAttributeValues = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useAttributeValues();

  const changeAttributeValuesForUpdate = (val: DataAttributeValues) => {
    dispatch(setIsAttributeValuesForUpdate(true));
    dispatch(setAttributeValuesForUpdate(val));
    dispatch(attributeValuesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el atributo con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar producto" en caso tal solo oprime en "
                Cancelar".`,
      okText: "Borrar producto",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteAttributeValues(valId)
          .then(() => {
            dispatch(refreshTableAttributeValues(true));
            message.success(`Se ha borrado el producto con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    changeAttributeValuesForUpdate,
    showDeleteConfirm,
  };
};
