import { useSelector } from "react-redux";
import { message } from "antd";
import { useParams } from "react-router-dom";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { selectOrderForUpdate } from "../../../../redux/selectors/orders.selectors";

import {
  changeOrderChangeShipping,
  changeOrderChangeShippingID,
  changeOrderModalIsOpen,
  changeOrderModalShippedIsOpen,
} from "../../../../redux/actions/orders.actions";
import {
  cancelProcessPaymentService,
  createProcessPaymentInStoreService,
  ordersService,
  shippingService,
} from "../../../../shared/services";
import { ChangeEvent, useState } from "react";
import { DataShipping } from "../../../../redux/@types";
import { setOrdersForUpdate } from "../../../../redux/actions/orders.actions"

export const useOrderById = () => {
  const params = useParams();
  const orderForUpdate = useSelector(selectOrderForUpdate);
  const dispatch = useAppDispatch();
  const [isDisable, setIsDisable] = useState(false);
  const [orderShippingShow, setOrderShippingShow] = useState<DataShipping>();
  const [textAreaVal, setTextAreaVal] = useState<undefined | string>(undefined)
  const [isErrorTextArea, setIsErrorTextArea] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const changeTextArea = (val:ChangeEvent<HTMLTextAreaElement>) =>{
    setTextAreaVal(val.target.value)
    setIsErrorTextArea(false);
  }
  const createShipping = async () => {
    return await shippingService
      .create({ order_id: Number(params.id) })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const getShipping = async () => {
    return await shippingService
      .find({
        query: {
          order_id: Number(params.id),
          $limit: 9999999999999,
          shipping_status_id: 1,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const changeOrderModal = () => {
    getShipping().then((res: any) => {
      if (res.data.length > 0) {
        dispatch(changeOrderChangeShippingID(res.data[res.data.length - 1].id));
        return console.log("ya existe una orden de envió");
      } else {
        createShipping().then((res2: any) => {
          if (!!res2) {
            dispatch(changeOrderChangeShippingID(res2?.id ? res2?.id : 0));
            dispatch(changeOrderChangeShipping([res2]));
            message.success(
              `Petición de order creada con éxito con el id: ${
                res2?.id ? res2?.id : 0
              }`
            );
          }
        });
      }
    });
    dispatch(changeOrderModalIsOpen());
  };

  const changeOrderShippedModal = (item: any) => {
    setOrderShippingShow(item);
    dispatch(changeOrderModalShippedIsOpen());
  };

  const getSingleOrder = async (id: number) => {
    return await ordersService
      .get(id)
      .then((res) => {
        return dispatch(setOrdersForUpdate(res));
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const changeProcessPaymentInStore = async () => {
    setIsDisable((stateAfter) => !stateAfter);
    return await createProcessPaymentInStoreService
      .create({
        order_id: Number(params.id),
      })
      .then((res) => {
        console.log({ res });
        getSingleOrder(Number(params.id));

        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      })
      .finally(() => setIsDisable((stateAfter) => !stateAfter));
  };

  const changeCancelProcessPayment = async () => {
    if(!textAreaVal || textAreaVal.trim().length === 0){
      setIsErrorTextArea(true)
      return false;
    }
    setIsDisable((stateAfter) => !stateAfter);
    return await cancelProcessPaymentService
      .create({
        order_id: Number(params.id),
        reason_for_cancel: textAreaVal,
      })
      .then((res) => {
        console.log({ res });
        setIsErrorTextArea(false)
        setIsModalVisible(false)
        message.error(res);
        return getSingleOrder(Number(params.id));
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      })
      .finally(() => setIsDisable((stateAfter) => !stateAfter));
  };


  return {
    //state
    orderForUpdate,
    isDisable,
    textAreaVal,
    orderShippingShow,
    isModalVisible,
    isErrorTextArea,
    // shippingIDForAdd,
    setIsModalVisible,
    //methods
    setIsDisable,
    //functions
    changeOrderModal,
    changeTextArea,
    changeOrderShippedModal,
    changeProcessPaymentInStore,
    changeCancelProcessPayment,
  };
};
