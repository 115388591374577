
import { message } from "antd";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { fulfillmentCitiesService } from "../../../../shared/services";
import api from "./api";

interface DataFulfillmentCities {
  id: number;
  fulfillment_company_id: number;
  location_city_id: number;
  integration_city_id: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  fulfillment_company: FulfillmentCompany;
  location_city: LocationCity;
}

interface FulfillmentCompany {
  id: number;
  name: string;
  description: string;
  path: null;
  type: string;
  status: string;
  integration: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

interface LocationCity {
  id: number;
  name: string;
  integration_id: string;
  state_id: number;
  dane_code: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

interface PropsInitialsState {
  minDeliveryDaysId: number | undefined,
  isEditMinDeliveryDays: boolean,
  textValueMinDeliveryDays: number | undefined,
}

const initialMinDeliveryDaysState: PropsInitialsState = {
  minDeliveryDaysId: undefined,
  isEditMinDeliveryDays: false,
  textValueMinDeliveryDays: undefined,
};

export const useTableFulfillmentCompanyById = () => {
  const { id } = useParams();
  const [minDeliveryDaysState, setMinDeliveryDaysState] = useState(
    initialMinDeliveryDaysState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [textIsLoading, setTextIsLoading] = useState(false);
  const [fulfillmentCities, setFulfillmentCities] = useState<
    DataFulfillmentCities[]
  >([]);

  const [loadingMark, setLoadingMark] = useState(false)

  const { minDeliveryDaysId, isEditMinDeliveryDays, textValueMinDeliveryDays } =
  minDeliveryDaysState;

  const getFulfillmentCities = async () => {
    setIsLoading(true);
    fulfillmentCitiesService
      .find({
        query: { fulfillment_company_id: Number(id), $limit: 999999999 },
      })
      .then((res) => {
        setFulfillmentCities(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (fulfillmentCities.length === 0) {
      getFulfillmentCities();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exitIntegrationState = () => {
    setMinDeliveryDaysState(initialMinDeliveryDaysState);
  };

  const changeStateEditIntegrationState = (
    id: number,
    textVal: number
  ) => {
    console.log(textVal);
    setMinDeliveryDaysState({
      minDeliveryDaysId: id,
      isEditMinDeliveryDays: true,
      textValueMinDeliveryDays: textVal,
    });
  };

  const addFulfillmentCity = async (location_city_id: number) => {
    if (isLoading) return;
    setIsLoading(true);
    fulfillmentCitiesService
      .create({
        fulfillment_company_id: Number(id),
        location_city_id,
      })
      .then(() => {
        getFulfillmentCities();
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        setIsLoading(false);
      });
  };

  const removeFulfillmentCity = async (id: number) => {
    if (isLoading) return;
    setIsLoading(true);
    fulfillmentCitiesService
      .remove(id)
      .then(() => {
        getFulfillmentCities();
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        setIsLoading(false);
      });
  };
  const updateIntegrationId = async (
    id: number,
    location_city_id: number,
    fulfillment_company_id: number
  ) => {
    if (isLoading) return;
    setIsLoading(true);
    if (!id) {
      await fulfillmentCitiesService
        .create({
          location_city_id,
          fulfillment_company_id,
        })
        .then(() => {
          getFulfillmentCities();
          setIsLoading(false);
          exitIntegrationState();
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
          setIsLoading(false);
        });
    } else {
      await fulfillmentCitiesService
        .patch(id, {
          location_city_id,
          fulfillment_company_id,
          min_delivery_days: textValueMinDeliveryDays,
        })
        .then(() => {
          getFulfillmentCities();
          setIsLoading(false);
          exitIntegrationState();
        })
        .catch((err: any) => {
          console.log(err);
          message.error(err.message);
          setIsLoading(false);
        });
    }
    console.log({ id, location_city_id, fulfillment_company_id });
  };

  const handleMarckUnmark = async () => {
    if (loadingMark) return

    setLoadingMark(true)

    await api.markOrUnmarkAllCities(Number(id))
      .then(() => {
        getFulfillmentCities();
      }).finally(() => {
        setLoadingMark(false)
      })
  }


  return {
    //state
    minDeliveryDaysState,
    minDeliveryDaysId,
    isEditMinDeliveryDays,
    textValueMinDeliveryDays,
    fulfillmentCities,
    isLoading,
    textIsLoading,
    loadingMark,
    //methods
    setMinDeliveryDaysState,
    //functions
    changeStateEditIntegrationState,
    exitIntegrationState,
    addFulfillmentCity,
    removeFulfillmentCity,
    updateIntegrationId,
    handleMarckUnmark,
  };
};
