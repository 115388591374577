import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  deleteProduct,
  refreshTableProducts,
  setIsProductsForUpdate,
} from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useProducts } from "./useProducts";

const { confirm } = Modal;

export const useTableProduct = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useProducts();

  const changeProductsForUpdate = (user: any) => {
    dispatch(setIsProductsForUpdate(true));
    navigate(`/products/${user.id}`);
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el producto con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar producto" en caso tal solo oprime en "
                Cancelar".`,
      okText: "Borrar producto",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteProduct(valId)
          .then(() => {
            dispatch(refreshTableProducts(true));
            message.success(`Se ha borrado el producto con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    changeProductsForUpdate,
    showDeleteConfirm,
  };
};
