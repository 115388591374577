import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { WrapperModalCities } from "../../../../modules";
import {
  selectAttributeValuesForUpdate,
  selectIsUpdateAttributeValues,
  selectModalAttributeValues,
} from "../../../../redux";
import { DataAttributeValues } from "../../../../redux/@types";
import { InputText } from "../inputs";
import { useAttributeValues } from "../../../../modules/attributesTest";

const { Title } = Typography;

export const ModalAttributeValues = () => {
  const modalAtributeValues = useSelector(selectModalAttributeValues);
  const isUpdateAtributeValues = useSelector(selectIsUpdateAttributeValues);
  const updateAtributeValues = useSelector(selectAttributeValuesForUpdate);

  const { isLoading, changeModalAttributeValues, onSubmitCreateOrUpdate } =
    useAttributeValues();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataAttributeValues>();
  return (
    <WrapperModalCities
      title={
        isUpdateAtributeValues
          ? `Actualizando valor del atributo: '${updateAtributeValues?.id!}'`
          : `Creando valor del atributo`
      }
      open={modalAtributeValues}
      width={"320px"}
      destroyOnClose
      onClose={changeModalAttributeValues}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre del atributo:
        </Title>
        <InputText
          placeHolder="Nombre atributo"
          name="name"
          control={control}
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateAtributeValues
          ? "Actualizar valor del atributo"
          : "Crear valor del atributo"}
      </Button>
    </WrapperModalCities>
  );
};
