import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import {
  attributesChangeModalState,
  deleteAttributes,
  refreshTableAttributes,
  setAttributesForUpdate,
  setIsAttributesForUpdate,
} from "../../../redux/actions/attributes.actions";
import { useAttributes } from "./useAttributes";
import { DataAttribute } from "../../../redux/@types";

const { confirm } = Modal;

export const useTableAttributes = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useAttributes();

  const changeAttributesForUpdate = (val: DataAttribute) => {
    dispatch(setIsAttributesForUpdate(true));
    dispatch(setAttributesForUpdate(val));
    dispatch(attributesChangeModalState());
  };

  const viewAttributes = (val: DataAttribute) => {
    dispatch(setIsAttributesForUpdate(true));
    navigate(`/attributes/${val.id}`);
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el atributo con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar producto" en caso tal solo oprime en "
                Cancelar".`,
      okText: "Borrar atributo",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteAttributes(valId)
          .then(() => {
            dispatch(refreshTableAttributes(true));
            message.success(`Se ha borrado el atributo con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    viewAttributes,
    changeAttributesForUpdate,
    showDeleteConfirm,
  };
};
