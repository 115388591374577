import * as authService from './authentication.service'
import { getService } from '../../clients/feathers.clients';
import { IBannersFind, IPaymentConfirmationsFin, IProductCategoriesFin, IProductCharacteristics2Fin, IProductCharacteristicsFin, IProductsFind, IRankingsFind, IShippingFin, PropsResGetUsersData } from '../../redux/@types';
import Service from './service.class'

//authenticate
export const authenticationService = authService
//services
export const usersService = new Service<PropsResGetUsersData | any>(getService('users'))
export const addressesUsersService = new Service<any>(getService('addresses'))
export const productCharacteristicsService = new Service<IProductCharacteristicsFin>(getService('characteristics'))
export const productCategoriesService = new Service<IProductCategoriesFin>(getService('categories'))
export const rankingsService = new Service<IRankingsFind>(getService('reviews'))
export const bannersService = new Service<IBannersFind>(getService('banners'))
export const bannerProductsService = new Service<any>(getService('banner-products'))
export const productsService = new Service<any>(getService('products'))
export const productCharacteristics2Service = new Service<IProductCharacteristics2Fin>(getService('products-characteristics'))
export const ordersService = new Service<any>(getService('orders'))

//TODO: si se implementa el servicio en el backend poner el nombre que le corresponde aquí abajo....
export const taxService = new Service<any>(getService('tax-rule'))
export const systemVariablesService = new Service<any>(getService('configurations'))
export const citiesService = new Service<any>(getService('locations-cities'))
export const statesService = new Service<any>(getService('locations-states'))
export const brandsService = new Service<any>(getService('brands'))
export const discountsService = new Service<any>(getService('discounts'))
export const attributesService = new Service<any>(getService('attributes'))
export const attributeValuesService = new Service<any>(getService('attribute-values'))
export const priceListService = new Service<any>(getService('price-list'))
export const contactsDirectoryService = new Service<any>(getService('contacts-directory'))
export const contactsDirectoryCategories = new Service<any>(getService('contacts-directory-categories'))
export const contactsDirectoryAttributes = new Service<any>(getService('contacts-directory-attributes'))
export const contactsDirectoryMedia = new Service<any>(getService('contacts-directory-media'))
export const walletBonusService = new Service<any>(getService('wallet-bonus'))
export const walletMovementsService = new Service<any>(getService('wallet-movements'))
export const coursesCategoriesService = new Service<any>(getService('courses-categories'))
export const coursesService = new Service<any>(getService('courses'))
export const courseSectionsService = new Service<any>(getService('course-sections'))
export const courseChaptersService = new Service<any>(getService('course-chapters'))
export const courseBenefitsService = new Service<any>(getService('course-benefits'))
export const cMSService = new Service<any>(getService('cms'))
export const workOffersCategoriesService = new Service<any>(getService('work-offers-categories'))
export const workOffersService = new Service<any>(getService('work-offers'))
export const fulfillmentCompanyService = new Service<any>(getService('fulfillment-company'))
export const fulfillmentCitiesService = new Service<any>(getService('fulfillment-cities'))
export const fulfillmentMatrixService = new Service<any>(getService('fulfillment-matrix'))
export const listCustomerGroupsService = new Service<any>(getService('customer-group'))
export const listCustomersService = new Service<any>(getService('customer-group-customers'))
export const priceListsService = new Service<any>(getService('price-list'))
export const contactsService = new Service<any>(getService('contact'))
export const storesService = new Service<any>(getService('stores'))


export const fulfillmentCitiesAllService = new Service<any>(getService('raw-queries/fulfillment-cities-all'))
export const shippingService = new Service<any>(getService('shipping'))
export const shippingQuantityService = new Service<any>(getService('shipping-details'))
export const ordersDetailsService = new Service<any>(getService('orders-details'))
export const paymentConfirmationsService = new Service<IPaymentConfirmationsFin>(getService('payment-confirmations'))
export const taxRuleService = new Service<any>(getService('tax-rule'))
export const productMediaService = new Service<any>(getService('products-media'))
export const orderStatusService = new Service<any>(getService('orders-status'))
export const createProcessPaymentInStoreService
= new Service<any>(getService('create-process-payment-instore'))
//1 status_id y pay_in_store
export const cancelProcessPaymentService
= new Service<any>(getService('cancel-process-payment'))
//funciona con 1 y 3 status_id
//s3 SERVICES
export const s3FileUploadService = new Service(getService('s3Client'))
// export const usersService = app.service('users')

export const paymethodConfigService = new Service(getService('paymethod-config'))

export const discountsTemplateService = new Service<any>(getService('discounts-by-quantity-template'))

export const discountsDetailsService = new Service<any>(getService('discounts-by-quantity-template-details'))


// export const paymethodConfigService = new Service(getService('paymethod-config'))