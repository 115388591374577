import { message, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  bannersChangeModalState,
  deleteBanner,
  refreshTableBanners,
  setBannersForUpdate,
  setIsBannersForUpdate,
} from "../../../redux";
import { DataBanner } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useBanners } from "./useBanners";

const { confirm } = Modal;

export const useTableBanners = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useBanners();

  const changeBannersForUpdate = (user: DataBanner) => {
    dispatch(setIsBannersForUpdate(true));
    dispatch(setBannersForUpdate(user));
    dispatch(bannersChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el banner con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar banner" en caso tal solo oprime en "
              Cancelar".`,
      okText: "Borrar banner",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteBanner(valId)
          .then(() => {
            dispatch(refreshTableBanners(true));
            message.success(`Se ha borrado el banner con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeBannersForUpdate,
    showDeleteConfirm,
  };
};
