import { useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { UndoOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, message, Table, Typography } from "antd";
import { useParams } from "react-router-dom";

import { OrderDetail } from "../../../../redux/@types";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { decimal } from "../../../../shared/utils/Helper";
import { ordersService } from "../../../../shared/services";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { setOrdersForUpdate } from "../../../../redux";

interface Props {
  data: OrderDetail[];
}

const { Title } = Typography;

export const InfoOrderDetails = ({ data }: Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const getRefreshSingleOrder = async () => {
    if (isLoading) return;
    setIsLoading(true);
    return await ordersService
      .get(Number(id))
      .then((res) => {
        setIsLoading(false);
        return dispatch(setOrdersForUpdate(res));
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
        return message.error(err.message);
      });
  };
  const columns: ColumnsType<OrderDetail> = [
    {
      title: "ID",
      width: 4,
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Foto",
      dataIndex: "product_main_image",
      key: "product_main_image",
      width: 5,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          <Avatar
            size={64}
            src={`${PATH_S3_BUCKET}/${item.product_main_image}`}
          />
        </Col>
      ),
    },
    {
      title: "ID producto",
      dataIndex: "product_id",
      width: 4,
      key: "product_id",
    },
    {
      title: "Talla",
      width: 4,
      render: (_, item) => {
        const record = JSON.parse(item.product_details_meta_data);
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {
              <Col style={{}}>
                <Title
                  style={{
                    marginBottom: "1.5px",
                  }}
                  level={5}
                >
                  {record.size}
                </Title>
              </Col>
            }
          </Col>
        );
      },
    },
    {
      title: "Color",
      render: (_, item) => {
        const record = JSON.parse(item.product_details_meta_data);
        return (
          <Col>
            {
              <Col>
                <Title
                  style={{
                    marginBottom: "1.5px",
                  }}
                  level={5}
                >
                  {record.color}
                </Title>
              </Col>
            }
          </Col>
        );
      },
    },
    {
      title: "Genero",
      render: (_, item) => {
        const record = JSON.parse(item.product_details_meta_data);
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {
              <Col style={{}}>
                <Title
                  style={{
                    marginBottom: "1.5px",
                  }}
                  level={5}
                >
                  {record.genre}
                </Title>
              </Col>
            }
          </Col>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Nombre",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Precio",
      dataIndex: "total_price_tax",
      key: "total_price_tax_incl",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>$ {decimal(item.total_price_tax_incl)}</Title>
        </Col>
      ),
    },
    {
      title: "Cantidad comprada",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Cantidad enviada",
      dataIndex: "sent",
      key: "sent",
    },
    {
      title: "Restante",
      dataIndex: "quantity-sent",
      key: "quantity-sent",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>{item.quantity - item.sent}</Title>
        </Col>
      ),
    },
  ];

  return (
    <Col
      span={24}
      style={{
        width: "100%",
        display: "flex",
        margin: "auto",
        // padding: 2.5,
        justifyContent: "flex-start",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <Button
        style={{ marginBottom: "5px" }}
        type="primary"
        onClick={getRefreshSingleOrder}
        shape="circle"
        disabled={isLoading}
        icon={<UndoOutlined />}
      />
      <Table
        style={{ width: "100%" }}
        pagination={false}
        bordered
        columns={columns}
        dataSource={data}
      />
    </Col>
  );
};
