import * as yup from 'yup'

export const validateUpdateUsers = yup.object().shape({
    first_name: yup
    .string()
    .min(4, 'El NOMBRE debe tener más de 3 caracteres')
    .nullable()
    .required("campo requerido"),
    last_name: yup
    .string()
    .nullable()
    .required("campo requerido"),
    dni: yup
    .string()
    .min(4, 'La cédula debe tener más de 3 caracteres numéricos')
    .nullable()
    .required("campo requerido"),
    phone: yup
    .string()
    .min(3, 'El TELÉFONO debe tener más de 4 caracteres numéricos')
    .nullable()
    .required("campo requerido"),
    email: yup
    .string()
    .email('Introduzca un correo electrónico válido.')
    .nullable()
    .required("campo requerido"),
    password: yup
    .string()
    .min(6, 'Debe introducir una contraseña de al menos 6 caracteres.'),
    role: yup
    .string()
    .oneOf(['user', 'admin', 'seller', 'integration'], 'Debes elegir entre estas opciones'),
    status: yup
    .string()
    .oneOf(['active', 'inactive', 'pending security verification', 'pending user data', 'pending validation'], 'Debes elegir entre estas opciones'),
})

