import { Spin, Typography } from "antd";
import { AdminMenuLayout, ModalProductCategories } from "../../../shared/components";
import { useProductCategories, useProductCategoryById, useTableProductCategories } from "../hooks";
import {
  WrapperCardCategories,
  WrapperCategoryById,
  WrapperImgNotFound,
} from "./styled";
import { TableProductCategories } from "./TableProductCategories";
import { selectModalProductCategories } from "../../../redux";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

const { Title } = Typography;

export const ProductCategoriesById = () => {
  const modalProductCategories = useSelector(selectModalProductCategories);
  const { dataProductCategoryById, arrOfSonCategory, isLoadingData } =
    useProductCategoryById();
    const {
      isUpdateProductCategory,
      formMethodsUpdate,
    } = useProductCategories();
  const {
    navigateSingleCategory,
    showDeleteConfirm,
    changeProductCategoriesForUpdate,
  } = useTableProductCategories();
  return (
    <AdminMenuLayout
      pageDescription="ProductCategoriesById"
      title="ProductCategoriesById"
    >
      <WrapperCardCategories>
        <WrapperCategoryById>
          <Title style={{ fontSize: "1.4rem", fontWeight: "600" }} level={5}>
            {dataProductCategoryById?.name}
          </Title>
          {isLoadingData ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Spin size="large" tip="Loading..." />
            </div>
          ) : (
            <>
              {arrOfSonCategory.length > 0 && (
                <>
                <TableProductCategories
                  parent_id={dataProductCategoryById?.id!}
                  />
                {modalProductCategories && isUpdateProductCategory && (
                  <FormProvider {...formMethodsUpdate}>
                    <ModalProductCategories />
                  </FormProvider>
                )}
                </>
              )}
              {arrOfSonCategory.length === 0 && (
                <WrapperImgNotFound>
                  <img
                    src="https://raw.githubusercontent.com/riimuru/AnimeLazer/a98a2f1b1127a68011c600f1bc43b967a5735d1f/public/404light.svg"
                    alt="notFoundPage404"
                  />

                  <Title
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "600",
                      marginTop: "1rem",
                    }}
                    level={5}
                  >
                    Esta categoría no tiene subcategorias, intente con otra categoría
                  </Title>
                </WrapperImgNotFound>
              )}
            </>
          )}
        </WrapperCategoryById>
      </WrapperCardCategories>
    </AdminMenuLayout>
  );
};
