import {
  ISingleAttribute,
  ISingleAttributeValues,
} from "./../@types/attributes";

export const ATTRIBUTE_MODAL_STATE = "[ ATTRIBUTES ] Attributes Modal State";

export interface IAttributeModalState {
  type: typeof ATTRIBUTE_MODAL_STATE;
}

export const GET_ATTRIBUTES_FOR_UPDATE =
  "[ ATTRIBUTES ] Get List Attributes Groups For Update";

export interface IGetAttributeForUpdate {
  type: typeof GET_ATTRIBUTES_FOR_UPDATE;
  payload: ISingleAttribute;
}

export const IS_ATTRIBUTES_FOR_UPDATE =
  "[ ATTRIBUTES ] Is List Attributes Groups For Update";

export interface IsAttributeForUpdate {
  type: typeof IS_ATTRIBUTES_FOR_UPDATE;
  payload: boolean;
}

export const SWITCH_ATTRIBUTES_REFRESH_TABLE =
  "[ ATTRIBUTES ] Switch List Attributes Groups Refresh Table";

export interface ISwitchAttributeRefreshTable {
  type: typeof SWITCH_ATTRIBUTES_REFRESH_TABLE;
  payload: boolean;
}

// ATTRIBUTE_VALUES

export const ATTRIBUTE_VALUES_MODAL_STATE =
  "[ ATTRIBUTE_VALUES ] Attribute values Modal State";

export interface IAttributeValuesModalState {
  type: typeof ATTRIBUTE_MODAL_STATE;
}

export const GET_ATTRIBUTE_VALUES_FOR_UPDATE =
  "[ ATTRIBUTE_VALUES ] Get List Attributes Groups For Update";

export interface IGetAttributeValuesForUpdate {
  type: typeof GET_ATTRIBUTE_VALUES_FOR_UPDATE;
  payload: ISingleAttributeValues;
}

export const IS_ATTRIBUTE_VALUES_FOR_UPDATE =
  "[ ATTRIBUTE_VALUES ] Is List Attributes Groups For Update";

export interface IsAttributeValuesForUpdate {
  type: typeof IS_ATTRIBUTE_VALUES_FOR_UPDATE;
  payload: boolean;
}

export const SWITCH_ATTRIBUTE_VALUES_REFRESH_TABLE =
  "[ ATTRIBUTE_VALUES ] Switch List Attributes Groups Refresh Table";

export interface ISwitchAttributeValuesRefreshTable {
  type: typeof SWITCH_ATTRIBUTE_VALUES_REFRESH_TABLE;
  payload: boolean;
}
