import { Button, Spin } from "antd";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout } from "../../../shared/components";
import { useProductById, useProducts } from "../hooks";
import {
  ProductsForm,
  ProductsGallery,
} from "./productById";
import { WrapperProductById, WrapperTabNav } from "./styled";
import { TableProductsChildren } from "./TableProductsChildren";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const ProductsById = () => {
  const { isUpdateProduct, formMethodsCreate, formMethodsUpdate } =
    useProducts();
  const { isValidate } = useProductById();

  const parent_id = formMethodsUpdate.getValues('parent_id')

  const params = useParams()
  const navigate = useNavigate();

  const [activeTabKey, setActiveTabKey] = useState("1");

  useEffect(() => {
    setActiveTabKey("1");
  }, [params.id]);

  const handleBack = () => {
    navigate(`/products/${parent_id}`);
  };

  return (
    <AdminMenuLayout pageDescription="ProductsById" title="ProductsById">
      <WrapperProductById>
        {!isValidate ? (
          <Spin size="large" />
        ) : (
          <>
            {!isUpdateProduct && (
              <FormProvider {...formMethodsCreate}>
                <ProductsForm />
              </FormProvider>
            )}
            {isUpdateProduct && (
              <>
              {parent_id ? <div style={{textAlign: 'left', width: '100%', marginBottom: 20}}><Button onClick={handleBack}>Volver a producto padre</Button></div> : null}
                <WrapperTabNav
                  activeKey={activeTabKey}
                  onChange={(key) => setActiveTabKey(key)}
                  defaultActiveKey="1"
                  items={[
                    {
                      label: `DATOS DEL PRODUCTO`,
                      key: "1",
                      children: (
                        <>
                          <FormProvider {...formMethodsUpdate}>
                            <ProductsForm />
                          </FormProvider>
                        </>
                      ),
                    },
                    {
                      label: `GALERIA`,
                      key: "2",
                      children: <ProductsGallery />,
                    },
                    ...(parent_id ? [] : [{
                      label: `SUB-PRODUCTOS`,
                      key: "3",
                      children: <TableProductsChildren />,
                    }]),
                  ]}
                />
              </>
            )}
          </>
        )}
      </WrapperProductById>
    </AdminMenuLayout>
  );
};
