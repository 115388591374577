import { IAttributeValuesState } from "./../@types/attributeValues";
import { ISingleAttributeValues } from "../@types";
import { attributeValuesAction } from "../action-types";

type attributeValuesType =
  | { type: typeof attributeValuesAction.ATTRIBUTE_VALUES_MODAL_STATE }
  | {
      type: typeof attributeValuesAction.GET_ATTRIBUTE_VALUES_FOR_UPDATE;
      payload: ISingleAttributeValues;
    }
  | {
      type: typeof attributeValuesAction.IS_ATTRIBUTE_VALUES_FOR_UPDATE;
      payload: boolean;
    }
  | {
      type: typeof attributeValuesAction.SWITCH_ATTRIBUTE_VALUES_REFRESH_TABLE;
      payload: boolean;
    };

const INITIAL_STATE: IAttributeValuesState = {
  attributeValuesForUpdate: undefined,
  isModalAttributeValuesOpen: false,
  isUpdateAttributeValues: false,
  refreshTableAttributeValues: false,
};

export default function attributeValuesReducer(
  state: IAttributeValuesState = INITIAL_STATE,
  action: attributeValuesType
): IAttributeValuesState {
  switch (action.type) {
    case attributeValuesAction.ATTRIBUTE_VALUES_MODAL_STATE:
      return {
        ...state,
        isModalAttributeValuesOpen: !state.isModalAttributeValuesOpen,
      };
    case attributeValuesAction.GET_ATTRIBUTE_VALUES_FOR_UPDATE:
      return {
        ...state,
        attributeValuesForUpdate: action.payload,
      };
    case attributeValuesAction.IS_ATTRIBUTE_VALUES_FOR_UPDATE:
      return {
        ...state,
        isUpdateAttributeValues: action.payload,
      };
    case attributeValuesAction.SWITCH_ATTRIBUTE_VALUES_REFRESH_TABLE:
      return {
        ...state,
        refreshTableAttributeValues: action.payload,
      };
    default:
      return state;
  }
}
