import * as yup from 'yup'

export const validateCreateProducts = yup.object().shape({
    name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    category_id:yup
    .string()
    .required('Campo requerido.'),
    category_id_2:yup
    .string(),
    category_id_3:yup
    .string(),
    // brand_id:yup
    // .string(),
    price:yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    discount_price:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .nullable(true)
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    quantity:yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    unit_measure:yup
    .string()
    .nullable(true),
    // .required('Campo requerido.')
    // .min(2, 'LA UNIDAD DE MEDIDA debe tener más de 2 caracteres'),
    tax_rule_id: yup
    .string(),
    // .required('Campo requerido.'),
    quantity_per_unit_measure:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .nullable(true),
    // .min(0, 'Debe de utilizar un numero que no sea negativo'),
    weight:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    height:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    width:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    long:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    description:yup
    .string()
    .nullable(true)
    // .required('Campo requerido.')
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    // status:yup
    // .string()
    // .required('Campo requerido.')
    // .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    position_more_sales:yup
    .number().typeError('Campo requerido.')
    // .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    user_guide_care:yup
    .string()
    .nullable(true)
    // .required('Campo requerido.')
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    // course:yup
    // .string()
    // .oneOf(["true", "false"], 'Debes elegir entre estas opciones'),
})