// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from "react";
import { message } from "antd";
import { useParams } from "react-router-dom";

import {
  listCustomersService,
  usersService,
} from "../../../../shared/services";
import { useIsMounted } from "../../../fulfillmentCompany";
import { PropsResGetUsersData } from "../../../../redux/@types";

// export interface Props {
//   id: number;
//   name: string;
//   createdAt: Date;
//   updatedAt: Date;
//   deletedAt: null;
//   customers: CustomerElement[];
//   price_lists: any[];
// }

// export interface CustomerElement {
//   id: number;
//   customer_group_id: number;
//   user_id: number;
//   createdAt: Date;
//   updatedAt: Date;
//   deletedAt: null;
//   customer: CustomerCustomer;
// }

// export interface CustomerCustomer {
//   id: number;
//   email: string;
//   first_name: string;
//   last_name: string;
//   phone: string;
//   profile_picture: null;
// }

interface Props {
  id: number;
  customer_group_id: number;
  user_id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

interface IObjUser {
  value: string;
  title: string;
}

export const useListCustomerGroupsById = () => {
  const { id } = useParams();
  const [isLoadingValuesSelected, setIsLoadingValuesSelected] = useState(false);
  const [valuesSelected, setValuesSelected] = useState<string[] | undefined>(
    []
  );
  const [listCustomers, setListCustomers] = useState<Props[]>();
  const [userList, setUserList] = useState<IObjUser[]>([]);

  const getListCustomers = async () => {
    await listCustomersService
      .find({ query: { customer_group_id: Number(id), $limit: 999999999 } })
      .then((res) => {
        // console.log(res);
        setListCustomers(res.data);
        setValuesSelected(res.data.map((item: Props) => `${item.user_id}`));
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };
  const getListOfUser = async () => {
    await usersService
      .find({ query: { $limit: 9999999999999 } })
      .then((res: PropsResGetUsersData) => {
        setUserList(
          res.data.map((item) => ({
            value: item.id.toString(),
            title: `${item.first_name} ${item.last_name}`,
          }))
        );
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (!isNaN(Number(id))) {
      getListCustomers();
      getListOfUser();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log({ valuesSelected });
  const addCustomers = async () => {
    if (isLoadingValuesSelected) return;
    setIsLoadingValuesSelected(true);
    let objData = [];
    for (let i = 0; i < valuesSelected.length; i++) {
      //console.log(userList?.find((e: any)=> valuesSelected?.includes(e.value)) , objData?.find((e: any)=> valuesSelected?.includes(e.user_id.toString())))
      if(!objData?.find((e: any)=> valuesSelected?.includes(e.user_id.toString())) && !listCustomers?.find((e: any)=> valuesSelected?.includes(e.value))){
        objData.push({
          customer_group_id: Number(id),
          user_id: Number(valuesSelected[i]),
        });
      }
    }
    if(objData.length > 0){
      return await listCustomersService
      .create(objData)
      .then((res) => {
        message.success(`Se añadió correctamente al usuario`);
        getListCustomers();
        return setIsLoadingValuesSelected(false);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
        return setIsLoadingValuesSelected(false);
      });
    }else{
      return setIsLoadingValuesSelected(false)
    }
  };

  const deleteCustomers = async (customId: string) => {
    await listCustomersService
      .remove(
        listCustomers.find((item) => item.user_id === Number(customId)).id
      )
      .then((res) => {
        message.success(`Se elimino correctamente al usuario de la lista`);
        getListCustomers();
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  return {
    //state
    userList,
    listCustomers,
    valuesSelected,
    isLoadingValuesSelected,
    //methods
    setValuesSelected,
    //functions
    addCustomers,
    deleteCustomers,
  };
};
