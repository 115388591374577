import { Button, Col, Row, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import {
  AdminMenuLayout,
  InputSelect,
  InputText,
} from "../../../../shared/components";
import { useCMS } from "../../hook";
import { WrapperCardCMS } from "../styled";

const { Title } = Typography;
export const PaymentById = () => {
  const { isLoading, onSubmitCreateOrUpdate } = useCMS();

  const { control, handleSubmit: onSubmit } = useFormContext<any>();

  return (
    <AdminMenuLayout pageDescription="MetododePago" title="MetododePago">
      <WrapperCardCMS>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              // padding: 5,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                marginBottom: "1.5px",
                color: "var(--primary)",
                marginRight: 7,
              }}
              level={2}
            >
              Actualizando Información de pago 
            </Title>
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Metodo de Pago:
            </Title>
            <InputText disabled={true} placeHolder="" name="paymment_method" control={control} />
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Estado:
            </Title>
            <InputSelect
              name="status"
              control={control}
              dataOptions={[
                { title: "Activo", value: "active" },
                { title: "Inactivo", value: "inactive" },
              ]}
              label="Estado"
            />
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Pasarela de Pago:
            </Title>
            <InputSelect
              name="gateway"
              control={control}
              dataOptions={[
                { title: "Epayco", value: "epayco" },
                { title: "Wompi", value: "wompi" },
              ]}
              label="Pasarela de Pago"
            />
          </Col>
          <Button
            style={{
              width: "100%",
              marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
          >
             Actualizar información
          </Button>
        </Row>
      </WrapperCardCMS>
    </AdminMenuLayout>
  );
};
