import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getBrands, getCategories, getDiscount, getTaxArr, selectProductBrandsArr,  selectProductTaxArr,  setCategoriesArr, setTaxArr } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
export interface PropsArrCategoriesRes {
  id:        number;
  name:      string;
  parent_id: number;
  path:      string;
  children:  PropsArrCategoriesRes[];
}

export const useProductModal = () => {
    const dispatch = useAppDispatch()
    const [arrCategories, setArrCategories] = useState<[] | PropsArrCategoriesRes[]>([])
    // const arrCategories = useSelector(selectProductCategoriesArr);
    const arrBrands = useSelector(selectProductBrandsArr);
    const arrTax = useSelector(selectProductTaxArr);
    const [brands, setBrands] = useState([])
    const [discountUnis, setDiscountUnis] = useState([])

    const getServicesBrands = async() =>{
      await getBrands().then((res) => {
        setBrands(res)
      }).catch((err) => {
        console.log(err)
      })
    }

    const getServicesDiscount = async() =>{
      await getDiscount().then((res) => {
        setDiscountUnis(res)
      }).catch((err) => {
        console.log(err)
      })
    }

    const getServicesProduct = async() =>{
        await getCategories()
        .then((res)=> {
          // console.log({res})
          // dispatch(setCategoriesArr(res))
          setArrCategories(res)
        })
        .catch((res) => {
          alert({res})
          // dispatch(setCategoriesArr([]))
          setArrCategories([])
        })
        // await getBrands()
        // .then((res)=> dispatch(setBrandsArr(res)))
        // .catch((res) => {
        //   alert({res})
        //   dispatch(setCategoriesArr([]))
        // })
        await getTaxArr()
        .then((res)=> dispatch(setTaxArr(res)))
        .catch((res) => {
          alert({res})
          dispatch(setCategoriesArr([]))
        })
    }

    useEffect(() => {
        // if(arrBrands.length === 0 && arrCategories.length === 0){
          getServicesProduct()
          getServicesBrands()
          getServicesDiscount()
        // }
    // }, [arrBrands, arrCategories])
    }, [])



  return {
    arrCategories,
    arrBrands,
    arrTax,
    brands,
    discountUnis,
  }
}
